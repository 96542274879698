export default [
    {
        path: '/reports',
        name: 'reports',
        props: true,
        component: () => import('@/views/reports/index.vue'),
        meta: {
            pageTitle: 'Laporan',
            breadcrumb: [
                {
                text: 'Laporan',
                active: true,
                },
            ],
        },
    },
]