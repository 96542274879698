import { password } from '@/@core/utils/validations/validations'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {createRouter,createWebHistory} from 'vue-router'
import dashboard from './routes/dashboard'
import login from './routes/login'
import profile from './routes/profile'
import forgotPassword from './routes/forgotPassword'
import roles from './routes/roles'
import error404 from './routes/error404'
import emergencies from '@/router/routes/emergencies'
import towings from '@/router/routes/towings'
import unitReplacement from '@/router/routes/unit-replacement'
import partReplacement from '@/router/routes/part-replacement'
import insuranceClaims from '@/router/routes/insurance-claims'
import customer from '@/router/routes/customer'
import units from '@/router/routes/units'
import catalog from '@/router/routes/catalog'
import contract from '@/router/routes/contract'
import quotation from '@/router/routes/quotation'
import place from '@/router/routes/place'
import promotion from '@/router/routes/promotion'
import services from './routes/services'
import settings from './routes/settings'
import reports from './routes/reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...login,
    ...profile,
    ...forgotPassword,
    ...roles,
    ...error404,
    ...emergencies,
    ...towings,
    // ...unitReplacement,
    ...partReplacement,
    ...insuranceClaims,
    ...customer,
    ...units,
    ...catalog,
    ...contract,
    ...quotation,
    ...place,
    ...promotion,
    ...services,
    ...settings,
    ...reports
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

router.beforeEach((to, from, next) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  
  const isAuthenticated = JSON.parse(sessionStorage.getItem('authenticated'));
  if (to.name !== 'login' && to.name !== 'forgot-password' && !isAuthenticated) next({ path: '/login' });
  // else if (to.name !== 'login' && !isAuthenticated) next({ path: '/' });
  else next();
});

export default router
